export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    AccessRequests = 7,
    Organizations = 8,
    JobSites = 9,
    Quotes = 10,
    PurchaseOrders = 11,
    PricingSheets = 12,
    HasTechnicianDashboard = 13,
    Jobs = 14,
    Jobs_CanAddNotes = 15,
    Jobs_CanManageBuildings = 16,
    Jobs_CanSeeAllJobs = 17,
    Jobs_Calendar = 18,
    TechnicianMaterials = 19,
}
